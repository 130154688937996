import React from 'react'
import styled from 'styled-components/macro';

import {SlaveNodesList} from 'components/containers'
import {BasisContainer, DataContainer, LayoutContainer} from 'components/containers'
import {withIntlCookieInfo} from 'components/hocs'
import {PageInactive} from './pages';

import '../../../../theme-hdi.scss'

const Wrapper = styled.div`
	height: 100%;
  max-width: 768px;
  margin: auto;
`;

const SlaveContainer = props => {
	const {profileId, languages, portal, bundle, sticker, locale} = props;
	const data = {profileId, languages, portal, bundle, sticker};

	const isActive = sticker.status === "active";
	const payloadIds = isActive ? ['public', 'shared', 'private'] : [];

	return (
		<BasisContainer {...data}>
			<LayoutContainer theme="theme-hdi">
				<DataContainer {...data} payloadIds={payloadIds}>
					<Wrapper>
						{isActive && <SlaveNodesList
								keyPrefix={`slave-${locale}`}
								subnodeFilters={{
									language: locale,
									stickerId: sticker.id
								}}
								containerActions={{slaveProps: props}}
							/>}
						{!isActive && <PageInactive {...props}/>}
					</Wrapper>
				</DataContainer>
			</LayoutContainer>
		</BasisContainer>
	);
};

export default withIntlCookieInfo(SlaveContainer, {
	C: "CookieInfo.Portal",
	profileId: true/*, bundleId: true, stickerId: true*/
});