import React, { useEffect } from "react";
import { api } from "services";
import _ from "lib/lodash";

import PageCollision from "./PageCollision/PageCollision";
import PageGlass from "./PageGlass/PageGlass";
import PageParking from "./PageParking/PageParking";
import PageEvent from "./PageEvent/PageEvent";
import PageOther from "./PageOther/PageOther";
import { useFormState } from "components/hooks";

const C = "profiles.helvetia-at.PageClaim";

const onPostEmail = async ({ formState, actions, claim, images }) => {
	const bundleProps = actions.getBundleProperties();
	const stickerProps = actions.getStickerProperties();

	const { email: emailBundle = {} } = bundleProps; // from: {name, address}, to, cc, subject, templateId
	const { email: emailSticker = {} } = stickerProps; // from: {name, address}, to, cc, subject, templateId

	// merge email properties from bundleProps and stickerProps
	const customizer = (objValue, srcValue, key) => {
		if (objValue && ["to", "cc", "bcc"].indexOf(key) > -1) {
			return objValue.concat(`,${[srcValue].filter(Boolean).join(",")}`);
		}
	};
	const email = {};
	_.mergeWith(email, emailBundle, customizer);
	_.mergeWith(email, emailSticker, customizer);

	const {
		id,
		owner,
		polno,
		driverPlate,
		driverName,
		driverTelno,
		driverEmail,
		driverLicenseAttached,
		driverLicenseImage,
		driverLicenseId,
		driverLicenseIssuer,
		driverLicenseClasses,
		hasKaskoCoverage,
		category,
		subcategory,
		claimId,
		datetime,
		location,
		partyAvailable,
		partyKnown,
		partyPlate,
		partyName,
		partyTelno,
		partyAddress,
		partyEmail,
		partyInsurer,
		partyHurt,
		partyCause,
		partyReason,
		damages,
		comments,
		categoryText,
		subcategoryText,
		partyKnownText,
		partyHurtText,
		partyCauseText,
		damagesText,
		detailsText,
		hasOwnDamagesText,
		isHPDamage,
		isKaskoDamage,
	} = formState;

	const location_label = location.label;
	const lat = (location.lat || 0).toFixed(4);
	const lng = (location.lng || 0).toFixed(4);

	const date = new Date(datetime).toLocaleDateString("de-DE");
	const time = new Date(datetime).toLocaleTimeString("de-DE");

	const url = window.location.href;

	const attachments = _.map(images, (image) => {
		const filename = _.get(image, "properties.label", `file_${claim.id}`);
		const extension = _.get(image, "storage.metadata.format", "jpg").replace("jpeg", "jpg");
		const path = _.get(image, "storage.fileUrl", "");
		const size = _.get(image, "storage.totalSize", 0);
		const mimetype = "image/jpeg";

		return { filename: filename + "." + extension, path, size, mimetype };
	});

	const attachmentLocs = _.map(images, (image) => _.get(image, "storage.fileLoc", "")).join(",");
	const attachmentUrls = _.map(images, (image) => _.get(image, "storage.fileUrl", "")).join(",");

	if (!!driverLicenseImage) {
		attachments.push({
			type: "base64",
			filename: "drivers-license.jpg",
			mimetype: "image/jpeg",
			path: driverLicenseImage?.split(",")?.[1],
			size: Math.max((driverLicenseImage.length - 814) / 1.37, 0),
		});
	}

	const saveOptions = {
		dataset: "helvetia_at",
		table: "pilot_19_claims",
		formData: {
			timestamp: new Date(),
			id,
			polno,
			owner,
			categoryId: category,
			categoryText,
			subcategoryId: subcategory,
			subcategoryText,
			partyKnown,
			partyPlate,
			partyName,
			partyTelno,
			partyAddress,
			partyEmail,
			partyInsurer,
			datetime,
			location_label,
			lat,
			lng,
			comments,
			driverName,
			driverTelno,
			driverPlate,
			driverEmail,
			driverLicenseAttached,
			driverLicenseImage,
			driverLicenseId,
			driverLicenseIssuer,
			driverLicenseClasses,
			// hasKaskoCoverage /* TODO: add hasKaskoCoverage field */
			attachmentLocs,
			attachmentUrls,
			partyHurt,
			partyCause,
			partyReason,
			partyKnownText,
			partyHurtText,
			partyCauseText,
			damagesText,
			detailsText,
			claimId,
		},
	};

	// nur im Haftpflichtfall
	if (!!driverEmail) _.mergeWith(email, { cc: driverEmail }, customizer);

	var params = {};

	// prepare email for Haftpflicht
	if (isHPDamage === "yes") {
		const emailHP = { ...email };
		// include involved party only in HP email, not in Kasko email
		if (!!partyEmail) _.mergeWith(emailHP, { cc: partyEmail }, customizer);

		params = {
			templateModel: {
				id,
				claimId,
				owner,
				polno,
				driverPlate,
				driverName,
				driverTelno,
				driverEmail,
				driverLicenseAttached,
				driverLicenseImage: !!driverLicenseImage ? "true" : "",
				driverLicenseId,
				driverLicenseIssuer,
				driverLicenseClasses,
				hasKaskoCoverage,
				category,
				subcategory,
				date,
				time,
				location_label,
				lat,
				lng,
				partyAvailable,
				partyKnown,
				partyPlate,
				partyName,
				partyTelno,
				partyAddress,
				partyEmail,
				partyInsurer,
				partyHurt,
				partyCause,
				partyReason,
				url,
				damages,
				comments,
				categoryText,
				subcategoryText,
				partyKnownText,
				partyHurtText,
				partyCauseText,
				damagesText,
				detailsText,
				/* Kasko-fields */
				damageType: "Haftpflicht",
				hasOwnDamagesText,
			},
			...emailHP,
			attachments,
			saveOptions,
		};

		// send out email for Haftpflicht
		await api.auth().post("postEmailPostmark", params);
	}

	if (isKaskoDamage === "yes") {
		params = {
			templateModel: {
				id,
				claimId,
				owner,
				polno,
				driverPlate,
				driverName,
				driverTelno,
				driverEmail,
				driverLicenseAttached,
				driverLicenseImage: !!driverLicenseImage ? "true" : "",
				driverLicenseId,
				driverLicenseIssuer,
				driverLicenseClasses,
				hasKaskoCoverage,
				category,
				subcategory,
				date,
				time,
				location_label,
				lat,
				lng,
				partyAvailable,
				partyKnown,
				partyPlate,
				partyName,
				partyTelno,
				partyAddress,
				partyEmail,
				partyInsurer,
				partyHurt,
				partyCause,
				partyReason,
				url,
				damages,
				comments,
				categoryText,
				subcategoryText,
				partyKnownText,
				partyHurtText,
				partyCauseText,
				damagesText,
				detailsText,
				/* Kasko-fields */
				damageType: "Kasko",
				hasOwnDamagesText,
			},
			...email,
			attachments,
			saveOptions: isHPDamage ? {} : saveOptions, // only save once to BigQuery
		};

		// send out email for Kasko
		await api.auth().post("postEmailPostmark", params);
	}
};

const PageClaim = (props) => {
	const { actions, item, wizardProps, previousStep } = props;
	const { resetToStep } = wizardProps;
	const [formState, dispatch] = useFormState();
	const { substep, category, subcategory, polno, datetime } = formState;

	const claim = _.get(
		_.filter(props.subitems, { properties: { component: "helvetia-at/custom-hvat-claim" } }),
		0,
		{}
	);
	const images = _.filter(props.subitems, { type: "image" });

	const onPreviousStep = () => {
		dispatch({ type: "FORM_GOTO_PREVIOUS_STEP" });
		previousStep();
	};

	const onClearForm = () => {
		if (!!_.size(images))
			_.forEach(images, (image) => actions.updateNodeFilters(image, { exclude: true }));
		dispatch({ type: "RESET_FORM" });
		resetToStep(0);
	};

	/*eslint-disable react-hooks/exhaustive-deps*/
	useEffect(() => {
		if (claim) {
			const id = actions.getId();
			const stickerId = actions.getStickerId();
			const claimNo = _.get(claim.properties, "claimNo", 0) + 1;
			const claimId = polno + "/" + stickerId + "/" + claimNo;
			const { component = "", data = "" } = _.get(claim, "properties", {});

			dispatch({ type: "FORM_INIT_CLAIM", component, data, id, claimNo, claimId });
		}
	}, []); // if inputs are given, endless re-rendering occurs due to formState change

	const onSubmit = async (values, { setSubmitting }) => {
		try {
			const formProperties = _.omit(formState, ["step", "substep", "showFeedback"]);
			const properties = { ...formProperties, datetime: new Date(datetime) };

			const fields = _.omitNullAndUndefined({
				...claim,
				properties: _.omitNullAndUndefined(properties),
			}); // remove null and undefined variables

			await actions.updateDocument(fields, true);

			await onPostEmail({ formState, actions, claim, images });
			dispatch({ type: "FORM_SUBMIT_SUBSTEP_COMMON_OVERVIEW" });
			onClearForm();
			setSubmitting(false);
			actions.showCustomInfo("Schaden erfolgreich gemeldet.");
			actions.gtmCustomEvent({ eventAction: `journey:${C}:submit` });
			actions.gtmCustomEvent({
				eventAction: "email:sent",
				eventLabel: (category || "x") + "." + (subcategory || "x"),
			});
		} catch (e) {
			console.error("GOT EMAIL ERROR", e);
			const msg = _.get(e, "response.data.error.response.body.errors[0].message", e);
			actions.raiseCustomError(new Error(msg));
			setSubmitting(false);
		}
	};

	const mergedProps = {
		...props,
		substep,
		cancel: onPreviousStep,
		item,
		images,
		onSubmit,
		onClearForm,
	};

	return (
		<>
			{category === "collision" && <PageCollision {...mergedProps} />}
			{category === "glass" && <PageGlass {...mergedProps} />}
			{category === "parking" && <PageParking {...mergedProps} />}
			{category === "event" && <PageEvent {...mergedProps} />}
			{category === "other" && <PageOther {...mergedProps} />}
		</>
	);
};

export default PageClaim;
